// #################################

// Разработано на: RISE PROJECT
// Разработчик: WOTKAD
// Сайт: WOTKAD.RU
// Сайт проекта: WOTKAD.RU/RISE

// #################################


let $images = $('img')
$images.addClass('blur');

// Проверяем каждое изображение
$('img').each(function() {

  let $img = $(this);

  // Когда изображение загружено, убираем класс с блюром
  $img.on('load', function() {
    setTimeout(function() {
      $img.removeClass('blur');
    }, 300);
  });

  // Если изображение уже загружено (например, кешированное), убираем блюр сразу
  if ($img[0].complete) {
    setTimeout(function() {
      $img.removeClass('blur');
    }, 300);
  }
});

require("./base/preloader");
require("./base/checkInternalLinks");
require("./base/routing/routing");
require("./base/checkTarget");
require("./base/currentYear");
require("./components/callbackForm");